<template lang="pug">
  .competitors-settings-table
    table
      thead
        tr
          th.shops.sortable(@click="handleSorting('name')")
            span {{ $t("general_settings.competitors_settings.table.own_shop") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.competitors {{ $t("general_settings.competitors_settings.table.competitor_shop") }}
          th.actions
      tbody
        CompetitorsSettingRow(
          v-for="shop in shops"
          :key="shop.id"
          :shop="shop"
        )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    mixins: [withSorting],

    props: {
      shops: {
        type: Array,
        default: () => new Array()
      },
      sortingData: Object
    },

    components: {
      CompetitorsSettingRow: () => import("./CompetitorsSettingRow")
    },

    methods: {
      handleShopChange(index, shopChanges) {
        this.$emit("change", index, shopChanges)
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .competitors-settings-table
    +listing-container(-19px)

    table
      +custom-table
      +matchings-table-header-sticky
      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          &.shops
            width: 30%

          &.competitors
            width: 60%

          &.actions
            width: 10%
</style>
